import { useEffect, useState, useRef } from 'react';

const FORMAT = /\/(\d{4})\/(\d{2})\/(\d{2})\/capture_(.*).jpg/;

const MONTH = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec'];

export default Webcam;

function Webcam({data, server}) {

  const [index, setIndex] = useState(0);
  const [auto, setAuto] = useState(false);
  const [site, setSite] = useState('webcam');
  const [live, setLive] = useState(false);

  useInterval(() => {
    if (auto)
      move(1)
  }, 100);


  function move(inc) {
    let mv = parseInt(index) + inc;
    if (mv < 0) mv = data[site].length - 1;
    if (mv >= data[site].length) mv = 0;
    setIndex(mv);
  }

  function choose(evt) {
    const newSite = evt.target.value;
    setSite(newSite);
    setIndex(Math.min(index, data[newSite].length - 1));
  }

  function switchAuto(force) {
    force === undefined ? setAuto(! auto) : setAuto(false);
    setLive(false);
  }

  function switchLive() {
    setAuto(false);
    setLive(true);
  }

  return <div >
    <div>  <label for="site">{live ? 'Live' : getDate(data[site][index])} - Position:</label>
      <select name="site" onChange={choose}>
        <option value="webcam">Devant</option>
        <option value="webcam2">Jardin</option>
      </select>
    </div>

    <div> 
      { renderLive(live, site, server, `/${data[site][index]}`)}
    </div>
    <div className={live ? 'hidden' : ''}>
      <input className="slider" onChange={evt => setIndex(evt.target.value)} 
          type="range" min="0" max={data[site].length - 1 || 100} value={index}           
          ></input>
    </div>
    <div>
      <button onClick={() => {switchAuto(false); move(-1);}}>&lt;</button>
      <button onClick={() => {switchAuto(false); move(1);}}>&gt;</button>
      <button className={auto ? 'selected' : ''} onClick={() => switchAuto()}>{auto ? "stop" : "play"}</button>
      <button className={live ? 'selected' : ''} onClick={() => switchLive()}>Live</button>
    </div>
  </div>
}

function renderLive(live, site, server, path) {
  if(! live)
    return <img src={`${server}${path}`} alt="ignored"></img>
  else
    return <img src={`${server}/${site === 'webcam' ? 'front' : 'back'}`} alt="ignored"></img>
}


//webcam/2021/03/08/capture_14:23:33.jpg => 8 Mars - 14h23
function getDate(file) {
  const m = FORMAT.exec(file);
  if(! m) return '';

  let day = parseInt(m[3]);
  const month = parseInt(m[2]);
  const year = parseInt(m[1]);
  let hour = parseInt(m[4].substring(0,2));
  const min = parseInt(m[4].substring(3,5));
  if(month <3 || (month === 3 && day < 14)) hour -= 5; else hour -= 4;
  if(hour < 0) {hour += 24; day -= 1;}

  return fill(day, ' ') + " " + MONTH[month - 1] + " " + year + " - " + fill(hour) + "h" + fill(min);
}

function fill(n, c = '0') {
  return n < 10 ? c + n : n;
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
