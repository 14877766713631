const DB_NAME = 'ImageDatabase';
const STORE_NAME = 'images';

const VERSION = "v1"; //allows to clear client cache

export function getFromDB(key, cb) {

    getDatastore((err, store) => {
        if (err) return cb(err);
        store.get(key).onsuccess = function (event) {
            const { result } = event.target

            if (result && result.version !== VERSION) {
                store.delete(key);
                return cb(null, null);
            }

            cb(null, result && result.value);
        }
    });
}

export function saveToDB(key, data, cb) {

    getDatastore((err, store) => {
        if (err) return cb(err);

        const payload = { 
            id: key, 
            value: data, 
            version: VERSION };

        store.put(payload).onsuccess = function (event) {
            cb(null, 'done');
        }
    });
}



function getDatastore(cb) {


    const request = window.indexedDB.open(DB_NAME, 1)

    request.onsuccess = function (event) {
        const db = event.target.result
        const transaction = db.transaction(STORE_NAME, 'readwrite')
        const datastore = transaction.objectStore(STORE_NAME);
        cb(null, datastore);
    }

    request.onerror = function (event) {
        cb(event);
    }

    request.onupgradeneeded = function (event) {
        const db = event.target.result
        db.createObjectStore(STORE_NAME, { keyPath: 'id' })
    }
}
