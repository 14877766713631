import { useEffect, useState } from 'react';

import { getFromDB, saveToDB } from './db'

import Webcam from './webcam';

const startDate = new Date();
startDate.setFullYear(2021);
startDate.setDate(22);
startDate.setMonth(1);
startDate.setHours(12);
const now = new Date();

const COUNT_DAYS = 13 * countDays(startDate);

const SERVER = `https://camera.solognot.net`;

const ALL_DATA = { webcam: [], webcam2: [] };

export default Loader;

function Loader() {
    const [done, setDone] = useState(false);
    const [currentDate, setCurrentDate] = useState(startDate);

    useEffect(() => {
        getDataForDate(currentDate, next => {
            if (next) {
                setCurrentDate(next);
            } else {
                setDone(true);
            }
        });
    }, [currentDate]);

    if (done) {
        return <Webcam data={ALL_DATA} server={SERVER} />
    } else {
        return <div >
            <audio autoplay >
                <source src="/audio.mp3" type="audio/mpeg"></source>
            </audio>
            <div>Chargement des images...</div>
            <div><progress min={1} max={COUNT_DAYS} value={ALL_DATA.webcam.length}></progress></div>
            <div><progress min={1} max={COUNT_DAYS} value={ALL_DATA.webcam2.length}></progress></div>
        </div>
    }
}

function getDataForDate(currentDate, cb) {

    getDataTypeForDate('webcam', currentDate, () => {
        getDataTypeForDate('webcam2', currentDate, () => {

            const next = incDate(currentDate);
            cb(next < now ? next : null);

        });
    });
}

function countDays(start) {
    let i = 1;
    for (; ; i++) {
        start = incDate(start);
        if (start >= now) break;
    }
    return i;
}

function incDate(date) {
    return new Date(date.getTime() + 24 * 3600 * 1000);
}

function getDataTypeForDate(type, currentDate, done) {

    const path = getType(type, currentDate);
    getFromDB(path, (err, data) => {
        if (err || !data) {
            getJSON(path, (err, json) => {
                addJSON(type, json);
                saveToDB(path, json, (err, msg) => {
                    done();
                });
            });
        } else {
            addJSON(type, data);
            done();
        }
    });
}

function getJSON(path, cb) {
    fetch(`${SERVER}/images?type=${encodeURIComponent(path)}`).then(resp => resp.json()).then(json => {
        cb(null, json);
    });
}

function addJSON(type, json) {
    json.forEach(f => {
        ALL_DATA[type].push(f);
        const img = new Image();
        img.src = `${SERVER}/${f}`;
        window[f] = img;
    });
}

function getType(type, date) {
    return `${type}/${fill(date.getFullYear())}/${fill(date.getMonth() + 1)}/${fill(date.getDate())}`;
}

function fill(n) {
    return n < 10 ? '0' + n : n;
}